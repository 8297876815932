import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import NonStretchedImage from 'components/NonStretchedImage'

import { Flex, Box } from 'components/Grid'
import { ActionLink } from 'components/ActionLink'
import { Section } from 'components/Section'
import { Text } from 'components/Typography'
import { Description } from './styles'

const Topic = ({ title, text, linkURL, image, imageLeft }) => {
  const direction = ['column', imageLeft ? 'row' : 'row-reverse']
  return (
    <Flex flexDirection={direction} mt={5}>
      <Box width={[1, 350]}>
        <NonStretchedImage fluid={image.childImageSharp.fluid} />
      </Box>
      <Description
        mt={[3, 0]}
        ml={[0, 0, imageLeft ? 5 : 0]}
        mr={[0, 0, imageLeft ? 0 : 5]}
      >
        <Text fontSize={3} fontWeight={600} mb={3}>
          {title}
        </Text>
        <Box mb={3}>{text}</Box>
        <ActionLink
          label="Прочети"
          linkURL={linkURL}
          color="colors.primary"
          hoverColor="colors.orange"
        />
      </Description>
    </Flex>
  )
}

Topic.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  linkURL: PropTypes.string.isRequired,
  imageLeft: PropTypes.bool.isRequired,
}

const TopicsTemplate = ({ data }) => {
  return (
    <Section title="Нашите теми" my={[5, 5, 6]}>
      {/* <StyledContainer as="section" py={6} my={4}>
      <Wrapper mx="auto" flexDirection="column" alignItems="center">
        <Heading as="h1">Нашите теми</Heading> */}
      {data.map((topic, i) => {
        const { title, text, linkURL, image } = topic
        return (
          <Topic
            key={shortid.generate()}
            title={title}
            text={text}
            linkURL={linkURL}
            image={image}
            imageLeft={i % 2 !== 0}
          />
        )
      })}
      {/* </Wrapper>
    </StyledContainer> */}
    </Section>
  )
}

TopicsTemplate.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      linkURL: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export const Topics = ({ data }) => {
  if (!data) {
    return null
  }
  return <TopicsTemplate data={data} />
}

Topics.propTypes = TopicsTemplate.propTypes
