import React from 'react'
import PropTypes from 'prop-types'
import styled, { themePx, themeGet } from 'util/style'
import Img from 'gatsby-image'

import { Section } from 'components/Section'
import CustomLink from 'components/CustomLink'
import Phone from '../../images/phone.inline.svg'

const Subheading = styled.h2`
  margin-bottom: ${themePx('space.4')};
  font-size: ${themePx('fontSizes.1')};
  font-weight: 400;
  text-align: center;
  line-height: 33px;
`
export const Button = styled.a`
  color: ${themeGet('colors.white')};
  font-size: ${themePx('fontSizes.3')};
  background-color: ${themeGet('colors.safenet')};
  border: 2px solid ${themeGet('colors.safenet')};
  border-radius: 4px;
  padding: ${themePx('space.1')} ${themePx('space.2')};
  outline: 0;
  text-align: center;
  position: relative;
  padding-left: 40px;
  text-decoration: none;

  &:hover {
    background-color: ${themeGet('colors.primary')};
    border: 2px solid ${themeGet('colors.primary')};
  }

  & > svg {
    height: 28px;
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
  }

  & svg path {
    fill: ${themeGet('colors.white')};
  }
`

const CallToActionTemplate = ({
  data: {
    heading,
    subheading,
    actionURL,
    actionLabel = 'Виж повече',
    linkType = 'internal',
    image,
    imageURL,
  },
}) => {
  return (
    <Section title={heading} my={[5, 5, 6]}>
      {image && (
        <CustomLink linkURL={imageURL} linkType={linkType}>
          <Img fixed={image.childImageSharp.fixed} alt={heading} />
        </CustomLink>
      )}
      <Subheading>{subheading}</Subheading>
      {actionURL && (
        <Button href={actionURL}>
          <Phone />
          {actionLabel}
        </Button>
      )}
    </Section>
  )
}

CallToActionTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export const SafeNetCTA = props => {
  return <CallToActionTemplate {...props} />
}
