import React, { Component } from 'react'
import PropTypes from 'prop-types'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import { Section } from 'components/Section'
import { Text } from 'components/Typography'
import Container from 'components/Container'
import ArrowRight from '../../images/arrow-right.inline.svg'

import { StyledForm } from './styles'

class MailChimpForm extends Component {
  state = {
    email: null,
    msg: null,
    result: null,
    working: false,
  }

  handleChange = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    const { email } = this.state

    if (email && email.length > 0) {
      this.setState({ working: true })
      addToMailchimp(email, this.state)
        .then(({ msg, result }) => {
          this.setState({ msg, result })
          this.setState({ working: false })

          if (result !== 'success') {
            throw msg
          }
        })
        .catch(() => {
          this.setState({ working: false })
        })
    }
  }

  render() {
    const { email, msg, result, working } = this.state

    return (
      <>
        {result !== 'success' ? (
          <StyledForm
            as="form"
            onSubmit={this.handleSubmit}
            flexDirection={['column', 'row']}
            alignItems={['center']}
          >
            <input
              type="email"
              onChange={this.handleChange}
              placeholder="E-mail адрес"
              name="email"
            />
            <button type="submit" value="" disabled={working}>
              Абонирай се
              <ArrowRight />
            </button>
          </StyledForm>
        ) : (
          <Text color="primary" textAlign="center">
            Благодарим, че се абонирахте за нашия месечен бюлетин!
          </Text>
        )}
        {email && result !== 'success' && msg && (
          <Text color="orange" textAlign="center" fontSize={0}>
            {msg.search('already subscribed') !== -1
              ? 'Already subscribed!'
              : msg}
          </Text>
        )}
      </>
    )
  }
}
const NewsletterTemplate = ({ data }) => {
  const { heading, subheading } = data
  return (
    <Section title={heading} my={[5, 5, 6]}>
      <Container maxWidth={['100%', '600px']}>
        <Text mb={4} fontSize={3} textAlign="center">
          {subheading}
        </Text>
      </Container>
      <MailChimpForm />
    </Section>
  )
}

NewsletterTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export const Newsletter = ({ data }) => {
  if (!data) {
    return null
  }
  return <NewsletterTemplate data={data} />
}

Newsletter.propTypes = NewsletterTemplate.propTypes
