import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import Content from 'components/Content'
import { ActionLink } from 'components/ActionLink'
import { Date } from 'components/News/Date'

import { ImageBox, TextBox, Title, Box } from 'components/News/styles'
import { Flex } from 'components/Grid'

export const Resource = ({ data, contentComponent }) => {
  const PostContent = contentComponent || Content
  return (
    <Flex mt={5} color="textColor" flexDirection={['column-reverse', 'row']}>
      <TextBox
        mr={[0, 3]}
        mt={[3, 0]}
        flexDirection="column"
        width={[1, 1 / 2]}
      >
        <Box>
          <Title mb={2}>{data.frontmatter.title}</Title>
          <Date date={data.frontmatter.date} tags={data.frontmatter.tags} />
          <PostContent my={2} content={data.excerpt || data.html} />
        </Box>
        <ActionLink
          label="Как можете да го получите?"
          linkURL={data.fields.slug}
        />
        <ActionLink
          label="Свали PDF"
          linkURL={data.frontmatter.file.publicURL}
          color="colors.orange"
          download
        />
      </TextBox>
      <ImageBox width={[1, 1 / 2]} justifyContent="center">
        <Img fixed={data.frontmatter.image.childImageSharp.fixed} alt="Image" />
      </ImageBox>
    </Flex>
  )
}

Resource.propTypes = {
  data: PropTypes.object.isRequired,
  contentComponent: PropTypes.func.isRequired,
}
