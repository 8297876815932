import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'

import { HTMLContent } from 'components/Content'
import { Section } from 'components/Section'

import { Resource } from './Resource'

const ResourcesTemplate = ({ data }) => {
  const { edges: resources } = data.allMarkdownRemark

  if (resources.length === 0) {
    return null
  }

  const first = resources[0].node

  if (!first) {
    return null
  }

  return (
    <Section title="Ресурси" my={[5, 5, 6]} py={[5, 5, 6]} bg="section.grey">
      <Resource data={first} contentComponent={HTMLContent} />
    </Section>
  )
}

ResourcesTemplate.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }).isRequired,
}

export const Resources = () => {
  const data = useStaticQuery(graphql`
    query ResourcesQuery {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { templateKey: { eq: "resource" } } }
      ) {
        edges {
          node {
            excerpt(pruneLength: 500, format: HTML)
            id
            html
            fields {
              slug
            }
            frontmatter {
              title
              image {
                childImageSharp {
                  fixed(width: 340, height: 488) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              file {
                publicURL
              }
              templateKey
              date(formatString: "MMMM DD, YYYY")
              tags
            }
          }
        }
      }
    }
  `)
  return <ResourcesTemplate data={data} />
}

export { Resource }
