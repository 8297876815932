import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
// import shortid from 'shortid'

import Layout from 'components/Layout'
import SEO from 'components/SEO'
// import Carousel from 'components/Carousel'
import { Hero } from 'components/Hero'
// import { Testimonial } from 'components/Testimonial'
import { Topics } from 'components/Topics'
import { Activities } from 'components/Activities'
import { Resources } from 'components/Resources'
import { Featured, News } from 'components/News'
import { Newsletter } from 'components/Newsletter'
import { SafeNetCTA } from 'components/SafeNet'
import { CallToAction } from 'components/CallToAction'

export const IndexPageTemplate = ({
  hero,
  topics,
  activities,
  newsletter,
  safenet,
}) => (
  <>
    <Hero data={hero} />
    <CallToAction
      data={{
        heading: 'Наръчниците за 1 и 5 клас са изчерпани',
        subheading:
          'Благодарим Ви за интереса към нашите наръчници “Добре дошли в 1. клас” и “Добре дошли в 5. клас”. Асоциация Родители иска да ви уведоми, че хартиените ни наръчници свършиха, но можете да изтеглите и разпространявате наръчниците в електронен вариант.',
        actionURL: '/resources',
        actionLabel: 'Изтегли',
      }}
    />
    <Featured />
    <Topics data={topics} />
    <SafeNetCTA data={safenet} />
    {/* <Carousel dots={false} fade>
      {testimonials.map(testimonial => (
        <Testimonial key={shortid.generate()} data={testimonial} />
      ))}
    </Carousel> */}
    <News />
    <Activities data={activities} />
    <Resources />
    <Newsletter data={newsletter} />
  </>
)

IndexPageTemplate.propTypes = {
  //   title: PropTypes.string.isRequired,
  hero: PropTypes.object.isRequired,
  newsletter: PropTypes.object.isRequired,
  safenet: PropTypes.object.isRequired,
  topics: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { footerData = {}, headerData = {} } = data

  let hData = {}
  if (headerData.edges && headerData.edges.length > 0) {
    hData = headerData.edges[0].node.frontmatter
  }

  let fData = {}
  if (footerData.edges && footerData.edges.length > 0) {
    fData = footerData.edges[0].node.frontmatter
  }

  return (
    <Layout headerData={hData} footerData={fData}>
      <SEO title={frontmatter.title} />
      <IndexPageTemplate
        title={frontmatter.title}
        hero={frontmatter.hero}
        testimonials={frontmatter.testimonials}
        topics={frontmatter.topics}
        activities={frontmatter.activities}
        newsletter={frontmatter.callToActions.newsletter}
        safenet={frontmatter.callToActions.safenet}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }).isRequired,
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    ...LayoutFragment
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        hero {
          image {
            childImageSharp {
              fluid(maxWidth: 850, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                presentationWidth
                presentationHeight
              }
            }
          }
          imageAlt
          heading
          subheading
          action
          actionURL
        }
        testimonials {
          author
          title
          quote
        }
        topics {
          title
          text
          linkURL
          image {
            childImageSharp {
              fluid(maxHeight: 333, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                presentationWidth
                presentationHeight
              }
            }
          }
        }
        activities {
          title
          image {
            publicURL
          }
          linkURL
        }
        callToActions {
          newsletter {
            heading
            subheading
          }
          safenet {
            heading
            subheading
            image {
              childImageSharp {
                fixed(width: 250, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
            imageURL
            actionURL
            actionLabel
            linkType
          }
        }
      }
    }
  }
`
