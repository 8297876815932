import styled from 'styled-components'
import {
  display,
  maxWidth,
  minWidth,
  height,
  maxHeight,
  minHeight,
  size,
  space,
} from 'styled-system'

const Container = styled.div`
  ${display}
  ${maxWidth}
  ${minWidth}
  ${height}
  ${maxHeight}
  ${minHeight}
  ${size}
  ${space}
`
Container.propTypes = {
  ...display.propTypes,
  ...maxWidth.propTypes,
  ...minWidth.propTypes,
  ...height.propTypes,
  ...maxHeight.propTypes,
  ...minHeight.propTypes,
  ...size.propTypes,
  ...space.propTypes,
}

export default Container
