import styled from 'util/style'
import { Flex } from 'components/Grid'

export const CardWrapper = styled(Flex)``

export const Card = styled(Flex)`
  margin-top: 100px;

  & > img {
    max-height: 60px;
  }

  & a {
    text-decoration: none;
  }
`
