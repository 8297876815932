import React from 'react'
import PropTypes from 'prop-types'
import styled, { themePx } from 'util/style'

import { Section } from 'components/Section'
import { ActionLink } from 'components/ActionLink'

const Subheading = styled.h2`
  margin-bottom: ${themePx('space.4')};
  font-size: ${themePx('fontSizes.1')};
  font-weight: 400;
  text-align: center;
  line-height: 33px;
`

const CallToActionTemplate = ({
  data: {
    heading,
    subheading,
    actionURL,
    actionLabel = 'Виж повече',
    linkType = 'internal',
  },
}) => {
  return (
    <Section title={heading} my={[5, 5, 6]}>
      <Subheading>{subheading}</Subheading>
      {actionURL && (
        <ActionLink
          label={actionLabel}
          linkURL={actionURL}
          linkType={linkType}
          color="colors.primary"
          hoverColor="colors.orange"
        />
      )}
    </Section>
  )
}

CallToActionTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export const CallToAction = props => {
  return <CallToActionTemplate {...props} />
}
