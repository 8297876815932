import React from 'react'
import PropTypes from 'prop-types'
import NonStretchedImage from 'components/NonStretchedImage'

import styled, { themeGet } from 'util/style'
import { Flex, Box, Container } from 'components/Grid'
import { ActionLink } from 'components/ActionLink'
import { Text } from 'components/Typography'
import CustomContainer from 'components/Container'

const Wrapper = styled(Flex)`
  max-width: 1150px;
  color: ${themeGet('colors.textColor')};
`

const HeroTemplate = ({ data }) => {
  const {
    image,
    imageAlt,
    heading,
    subheading,
    action,
    actionURL,
    linkType = 'internal',
  } = data
  return (
    <Container as="section" my={[5, 5, 6]}>
      <Wrapper
        mx="auto"
        px={[3, 3, 0]}
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box width={1} mb={4}>
          <NonStretchedImage
            fluid={image.childImageSharp.fluid}
            alt={imageAlt}
          />
        </Box>
        <Text fontSize={[32, 36, 40]} textAlign="center" as="h1" my={4}>
          {heading}
        </Text>
        <CustomContainer maxWidth={['100%', '600px']}>
          <Text mb={4} fontSize={3} textAlign="center" lineHeight="33px">
            {subheading}
          </Text>
        </CustomContainer>
        <ActionLink
          label={action}
          linkURL={actionURL}
          linkType={linkType}
          color="colors.primary"
          hoverColor="colors.orange"
        />
      </Wrapper>
    </Container>
  )
}

HeroTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export const Hero = ({ data }) => {
  if (!data) {
    return null
  }
  return <HeroTemplate data={data} />
}

Hero.propTypes = HeroTemplate.propTypes
