import styled, { themeGet, themePx, media } from 'util/style'
import { Flex } from 'components/Grid'

export const StyledForm = styled(Flex)`
  & > input[type='email'] {
    width: 300px;
    border: 2px solid ${themeGet('colors.separator.grey')};
    border-radius: 4px;
    padding: ${themePx('space.1')} ${themePx('space.2')};
    margin-right: ${themePx('space.3')};
    ${media.phone`margin-bottom: ${themePx('space.3')};`}
    outline: 0;

    &:focus {
      border: 2px solid ${themeGet('colors.primary')};
    }
  }

  & > button[type='submit'] {
    color: ${themeGet('colors.white')};
    background-color: ${themeGet('colors.primary')};
    border: 2px solid ${themeGet('colors.primary')};
    border-radius: 4px;
    padding: ${themePx('space.1')} ${themePx('space.2')};
    outline: 0;

    &:hover {
      background-color: ${themeGet('colors.orange')};
      border: 2px solid ${themeGet('colors.orange')};
    }

    &:disabled {
      background-color: ${themeGet('colors.gray')};
      border: 2px solid ${themeGet('colors.gray')};
    }

    & > svg {
      margin-left: ${themePx('space.2')};
    }

    & > svg g {
      fill: ${themeGet('colors.white')};
    }
  }
`
