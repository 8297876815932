import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'

import { ActionLink } from 'components/ActionLink'
import { Section } from 'components/Section'
import { Text } from 'components/Typography'
import { Card, CardWrapper } from './styles'

export const ActivitiesTemplate = ({ data }) => {
  return (
    <Section title="Дейности" my={[5, 5, 6]}>
      <CardWrapper width={1} flexWrap="wrap" justifyContent="space-around">
        {data.map(activity => {
          const { title, image, linkURL } = activity
          return (
            <Card
              key={shortid.generate()}
              width={[1, 1 / 3]}
              alignItems="center"
              justifyContent="space-between"
              flexDirection="column"
            >
              <img src={image.publicURL} alt={title} />
              <Text fontSize={3} fontWeight="bold" mt={4} mb={2}>
                {title}
              </Text>
              <ActionLink label="Прочети" linkURL={linkURL} />
            </Card>
          )
        })}
      </CardWrapper>
    </Section>
  )
}

ActivitiesTemplate.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired,
      linkURL: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export const Activities = ({ data }) => {
  if (!data) {
    return null
  }
  return <ActivitiesTemplate data={data} />
}

Activities.propTypes = ActivitiesTemplate.propTypes
